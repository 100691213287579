import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import sales from "./slices/salesSlice";
import purchase from "./slices/purchaseSlice";
import recievable from "./slices/receivableSlice";
import expense from "./slices/expenseSlice";
import dashboard from "./slices/dashboardSlice";
import item from "./slices/itemSlice";
import cashinvault from "./slices/cashinvaultSlice";
import summary from "./slices/summarySlice";
import packing from "./slices/packingSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    sales,
    expense,
    dashboard,
    item,
    cashinvault,
    purchase,
    recievable,
    summary,
    packing,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
