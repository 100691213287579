import fetch from "auth/FetchInterceptor";

const PackingService = {};

PackingService.index = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/packing?${queryString}`,
    method: "get",
  });
};

PackingService.setStatus = function (id, status) {
  return fetch({
    url: `/packing/${id}/status`,
    method: "put",
    params: {
      status,
    },
  });
};

export default PackingService;
