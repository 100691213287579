import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import TransactionService from "services/TransactionService";

export const index = createAsyncThunk(
  "purchase/index",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TransactionService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  drawer: false,
  transactions: [],
  transaction: {},
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = !state.drawer;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (state) => {
        state.loading = true;
      })
      .addCase(index.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(index.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { setDrawer, setTransaction } = purchaseSlice.actions;

export default purchaseSlice.reducer;
