import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import ExpensesService from "services/ExpensesService";

export const index = createAsyncThunk(
  "expenses/index",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ExpensesService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  drawer: false,
  expenses: [],
  expense: {},
};

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    setExpense: (state, action) => {
      state.expense = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = !state.drawer;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (state) => {
        state.loading = true;
      })
      .addCase(index.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = action.payload;
      })
      .addCase(index.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { setDrawer, setExpense } = expenseSlice.actions;

export default expenseSlice.reducer;
