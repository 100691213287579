import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import SummaryService from "services/SummaryService";

export const index = createAsyncThunk(
  "summary/index",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SummaryService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  summary: [],
};

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (state) => {
        state.loading = true;
      })
      .addCase(index.fulfilled, (state, action) => {
        state.loading = false;
        state.summary = action.payload;
      })
      .addCase(index.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {} = summarySlice.actions;

export default summarySlice.reducer;
