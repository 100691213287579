import fetch from "auth/FetchInterceptor";

const ExpensesService = {};

ExpensesService.index = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/expenses?${queryString}`,
    method: "get",
  });
};

ExpensesService.today = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/expenses/today?${queryString}`,
    method: "get",
  });
};

export default ExpensesService;
