import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import CashInVaultService from "services/CashInVaultService";

export const indexRemaining = createAsyncThunk(
  "expenses/indexRemaining",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CashInVaultService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const indexRequest = createAsyncThunk(
  "expenses/indexRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CashInVaultService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loadingRemaining: false,
  loadingRequest: false,
  message: "",
  showMessage: false,
  drawer: false,
  cashinvaultDataRemaining: [],
  cashinvaultDataRequest: [],
};

export const cashinvaultSlice = createSlice({
  name: "cashinvault",
  initialState,
  reducers: {
    setExpense: (state, action) => {
      state.expense = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = !state.drawer;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(indexRemaining.pending, (state) => {
        state.loadingRemaining = true;
      })
      .addCase(indexRemaining.fulfilled, (state, action) => {
        state.loadingRemaining = false;
        state.cashinvaultDataRemaining = action.payload;
      })
      .addCase(indexRemaining.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loadingRemaining = false;
      })
      .addCase(indexRequest.pending, (state) => {
        state.loadingRequest = true;
      })
      .addCase(indexRequest.fulfilled, (state, action) => {
        state.loadingRequest = false;
        state.cashinvaultDataRequest = action.payload;
      })
      .addCase(indexRequest.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loadingRequest = false;
      });
  },
});

export const { setDrawer, setExpense } = cashinvaultSlice.actions;

export default cashinvaultSlice.reducer;
