import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemsService from "services/ItemsService";
import { message } from "antd";

const searchParams = {
  pageSize: 10,
  page: 1,
  search: "",
  branch: "",
};

export const index = createAsyncThunk(
  "item/index",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ItemsService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.error || "Error");
    }
  }
);

export const addItem = createAsyncThunk(
  "item/addItem",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await ItemsService.add(data);

      await dispatch(index(searchParams));
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.error || "Error");
    }
  }
);

export const updateItem = createAsyncThunk(
  "item/updateItem",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { id, params } = payload;

      const response = await ItemsService.update(id, params);

      await dispatch(index(searchParams));
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  drawerType: "create",
  drawer: false,
  items: [],
  item: {},
};

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    toggleDrawer: (state, action) => {
      state.drawer = !state.drawer;
      state.drawerType = action.payload;
    },
    setItem: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (state) => {
        state.loading = true;
      })
      .addCase(index.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(index.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(addItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(addItem.fulfilled, (state, action) => {
        message.success("New item has been added.");
        state.drawer = false;

        state.item = {};
      })
      .addCase(addItem.rejected, (state, action) => {
        message.error(action.payload);

        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(updateItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        message.success("Item has been updated.");
        state.drawer = false;
        state.item = {};
      })
      .addCase(updateItem.rejected, (state, action) => {
        message.error(action.payload);
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { toggleDrawer, setItem } = itemSlice.actions;

export default itemSlice.reducer;
