import fetch from "auth/FetchInterceptor";

const TransactionService = {};

TransactionService.index = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/transactions?${queryString}`,
    method: "get",
  });
};

TransactionService.today = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/transactions/today?${queryString}`,
    method: "get",
  });
};

export default TransactionService;
