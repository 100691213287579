import fetch from "auth/FetchInterceptor";

const ItemsService = {};

ItemsService.index = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/items?${queryString}`,
    method: "get",
  });
};

ItemsService.add = function (data) {
  return fetch({
    url: `/items`,
    method: "post",
    data,
  });
};

ItemsService.update = (id, params) => {
  return fetch({
    url: `/items/${id}`,
    method: "put",
    params,
  });
};

export default ItemsService;
