import fetch from "auth/FetchInterceptor";

const ReceivableService = {};

ReceivableService.index = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/receivables?${queryString}`,
    method: "get",
  });
};

export default ReceivableService;
