import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  branches: JSON.parse(localStorage.getItem("branches")) || [],
  user: JSON.parse(localStorage.getItem("user")) || {},
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.login({ email, password });
      const token = response.data.access_token;
      const branches = response.data.branches;
      const user = response.data.user;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("branches", JSON.stringify(branches));
      localStorage.setItem(AUTH_TOKEN, token);
      return { token, branches, user };
    } catch (err) {
      console.log("???", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  localStorage.clear();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.branches = action.payload.branches;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.branches = [];
        state.user = {};
        state.token = null;

        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
