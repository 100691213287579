import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.cashinvaultrequest",
    path: `${APP_PREFIX_PATH}/cashinvaultrequest`,
    component: React.lazy(() => import("views/app-views/cashinvaultrequest")),
  },
  {
    key: "dashboard.cashinvaultremaining",
    path: `${APP_PREFIX_PATH}/cashinvaultremaining`,
    component: React.lazy(() => import("views/app-views/cashinvaultremaining")),
  },
  {
    key: "dashboard.sales",
    path: `${APP_PREFIX_PATH}/sales`,
    component: React.lazy(() => import("views/app-views/sales")),
  },
  {
    key: "dashboard.wholesale",
    path: `${APP_PREFIX_PATH}/wholesale`,
    component: React.lazy(() => import("views/app-views/wholesale")),
  },
  {
    key: "dashboard.purchases",
    path: `${APP_PREFIX_PATH}/purchases`,
    component: React.lazy(() => import("views/app-views/purchases")),
  },
  {
    key: "dashboard.expenses",
    path: `${APP_PREFIX_PATH}/expenses`,
    component: React.lazy(() => import("views/app-views/expenses")),
  },
  {
    key: "dashboard.summary",
    path: `${APP_PREFIX_PATH}/summary`,
    component: React.lazy(() => import("views/app-views/summary")),
  },
  {
    key: "dashboard.items",
    path: `${APP_PREFIX_PATH}/items`,
    component: React.lazy(() => import("views/app-views/items")),
  },
  {
    key: "dashboard.receivables",
    path: `${APP_PREFIX_PATH}/receivables`,
    component: React.lazy(() => import("views/app-views/receivables")),
  },
];
