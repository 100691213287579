import fetch from "auth/FetchInterceptor";

const DashboardService = {};

DashboardService.index = function () {
  return fetch({
    url: `/dashboards`,
    method: "get",
  });
};

DashboardService.charts = function () {
  return fetch({
    url: `/dashboards/charts`,
    method: "get",
  });
};

export default DashboardService;
