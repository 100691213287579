import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import DashboardService from "services/DashboardService";
import ExpensesService from "services/ExpensesService";
import TransactionService from "services/TransactionService";

export const index = createAsyncThunk(
  "dashboard/index",
  async (_, { rejectWithValue }) => {
    try {
      const response = await DashboardService.index();

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getChartsData = createAsyncThunk(
  "dashboard/getChartsData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await DashboardService.charts();

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getExpensesTableData = createAsyncThunk(
  "dashboard/getExpensesTableData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ExpensesService.today(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getTransactionTableData = createAsyncThunk(
  "dashboard/getTransactionTableData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TransactionService.today(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  dashboard: [],
  charts: [],
  transactionData: [],
  expensesData: [],
  transactionDataLoading: false,
  expensesDataLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (state) => {
        state.loading = true;
      })
      .addCase(index.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboard = action.payload;
      })
      .addCase(index.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getChartsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChartsData.fulfilled, (state, action) => {
        state.loading = false;
        state.charts = action.payload;
      })
      .addCase(getChartsData.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getExpensesTableData.pending, (state) => {
        state.expensesDataLoading = true;
      })
      .addCase(getExpensesTableData.fulfilled, (state, action) => {
        state.expensesDataLoading = false;
        state.expensesData = action.payload;
      })
      .addCase(getExpensesTableData.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.expensesDataLoading = false;
      })
      .addCase(getTransactionTableData.pending, (state) => {
        state.transactionDataLoading = true;
      })
      .addCase(getTransactionTableData.fulfilled, (state, action) => {
        state.transactionDataLoading = false;
        state.transactionData = action.payload;
      })
      .addCase(getTransactionTableData.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.transactionDataLoading = false;
      });
  },
});

export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
