import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import PackingService from "services/PackingService";

export const index = createAsyncThunk(
  "purchase/index",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PackingService.index(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const setStatus = createAsyncThunk(
  "purchase/setStatus",
  async (data, { rejectWithValue }) => {
    try {
      const { id, status } = data;

      const response = await PackingService.setStatus(id, status);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  drawer: false,
  packingLists: [],
  list: {},
};

export const packingSlice = createSlice({
  name: "packing",
  initialState,
  reducers: {
    setPacking: (state, action) => {
      state.list = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = !state.drawer;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (state) => {
        state.loading = true;
      })
      .addCase(index.fulfilled, (state, action) => {
        state.loading = false;
        state.packingLists = action.payload;
      })
      .addCase(index.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      .addCase(setStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(setStatus.fulfilled, (state, action) => {
        state.loading = false;
        window.location.reload();
      })
      .addCase(setStatus.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { setDrawer, setPacking } = packingSlice.actions;

export default packingSlice.reducer;
