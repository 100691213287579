import fetch from "auth/FetchInterceptor";

const CashInVaultService = {};

CashInVaultService.index = function (params) {
  // Construct query string from params object
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return fetch({
    url: `/cashinvault?${queryString}`,
    method: "get",
  });
};

export default CashInVaultService;
